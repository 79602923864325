const doughnutBackgroundCache = {};

export const addAlpha = (color, opacity) => {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);

  return color + _opacity.toString(16).toUpperCase();
};

function getDoughnutBackground(colorFrom, colorTo) {
  const key = `${colorFrom}${colorTo}`;

  if (doughnutBackgroundCache[key] !== undefined) {
    return doughnutBackgroundCache[key];
  }

  const element = document.createElement('canvas');
  const ctx = element.getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, 0, 220);
  gradient.addColorStop(0, colorFrom);
  gradient.addColorStop(0.98, colorTo);
  element.remove();
  doughnutBackgroundCache[key] = gradient;

  return gradient;
}

const data = {
  datasets: [{
    fill: true,
    backgroundColor: [getDoughnutBackground('#00C9F8', '#01F9DD'), '#244757'],
    pointRadius: 0,
    pointHitRadius: 100,
    borderWidth: 0,
  }],
};

const options = {
  cutoutPercentage: 75,
  spanGaps: false,
  legend: {
    display: false,
  },
  maintainAspectRatio: false,
  backgroundColor: [],
  tooltips: { enabled: false },
};

export const getDoughnutData = ({ mxrScoreTotal, mxrScoreTotalPass, backgroundColor }) => {
  let mxrScorePassPerc = 0;

  if (mxrScoreTotal) {
    mxrScorePassPerc = ((mxrScoreTotalPass / mxrScoreTotal) * 100).toFixed(2);
  }
  const mxrScoreFailPerc = ((10000 - mxrScorePassPerc * 100) / 100).toFixed(2);

  return {
    options,
    data: {
      ...data,
      datasets: [{
        ...data.datasets[0],
        data: [mxrScorePassPerc, mxrScoreFailPerc],
        ...(backgroundColor && { backgroundColor: [backgroundColor, addAlpha(backgroundColor, 0.3)] }),
      }],
    },
    passPerc: mxrScorePassPerc,
  };
};

export const getDoughnutBackgroundColor = ({ color, size, disabled, empty }) => {
  if (disabled) {
    return 'rgba(255, 255, 255, 0.23)';
  }

  if (empty) {
    return 'rgba(67, 71, 96, 0.40)';
  }

  if (['tiny', 'small', 'medium'].includes(size)) {
    return color;
  }

  return null;
};
