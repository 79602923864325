import { useCallback, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { useParams, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import moment from 'moment';

import queryClient from 'query-client';

import {
  AuthLogoIcon, EmailSidebarIcon, SMTPSidebarIcon, BlacklistSidebarIcon,
  DNSSidebarIcon, DKIMSidebarIcon, SpamAssassinSidebarIcon, SmallClockIcon,
  SpamAssassinResultsIcon, EmailResultsIcon, SMTPResultsIcon, DNSResultsIcon,
  DKIMResultsIcon, SpamResultsIcon, CupOfTeaIcon, ErrorIcon, TwitterIcon,
  LinkedinSmallIcon, YoutubeIcon, FacebookIcon, DownloadIcon, CheckIcon, MinusIcon,
  ArrowLeftIcon, HoneypotSidebarIcon, HoneypotResultsIcon, RefreshIcon, LogoLightIcon,
} from 'assets/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { faChartRadar } from '@fortawesome/sharp-light-svg-icons';

import { routes } from 'routes';

import { emailApi } from 'resources/email';
import { userApi } from 'resources/user';
import { mxrightApi } from 'resources/mxright';

import { Link, IconButton } from '@mui/material';

import ScoreCircle from 'components/ScoreCircle';
import Speedometer from 'components/Speedometer';
import AuthMenu from 'components/AuthMenu';
import Button from 'components/Button';
import ShareReportModal from 'components/ShareReportModal';
import CreateAccountHeader from 'components/CreateAccountHeader';

import Header from 'layouts/authorized/components/Header';

import useWindowDimensions from 'hooks/useWindowDimensions';

import ReportHistorySelect from './components/ReportHistorySelect';
import GroupResultSection from './components/GroupResultSection';
import BlacklistMatrix from './components/BlacklistMatrix';
import BlacklistServiceItem from './components/BlacklistServiceItem';

import './styles.scss';

const MAX_RETRY_ATTEMPTS = 5;

export const EMAIL_REPORT_GROUPS = {
  EMAIL: 'email',
  SMTP: 'smtp',
  SPAM: 'spam',
  HONEYPOT: 'honeypot',
  DNS: 'dns',
  DKIM: 'dkim',
  SPAM_ASSASSIN: 'spamassassin',
};

export const getServiceGroupsData = ({ user }) => [
  {
    id: EMAIL_REPORT_GROUPS.EMAIL,
    sidebarIcon: <EmailSidebarIcon />,
    resultsIcon: <EmailResultsIcon />,
  },
  {
    id: EMAIL_REPORT_GROUPS.SMTP,
    sidebarIcon: <SMTPSidebarIcon />,
    resultsIcon: <SMTPResultsIcon />,
  },
  {
    id: EMAIL_REPORT_GROUPS.SPAM,
    sidebarIcon: <BlacklistSidebarIcon />,
    resultsIcon: <SpamResultsIcon />,
  },
  {
    id: EMAIL_REPORT_GROUPS.HONEYPOT,
    sidebarIcon: <HoneypotSidebarIcon />,
    resultsIcon: <HoneypotResultsIcon />,
  },
  {
    id: EMAIL_REPORT_GROUPS.DNS,
    sidebarIcon: <DNSSidebarIcon />,
    resultsIcon: <DNSResultsIcon />,
  },
  {
    id: EMAIL_REPORT_GROUPS.DKIM,
    sidebarIcon: <DKIMSidebarIcon />,
    resultsIcon: <DKIMResultsIcon />,
  },
  {
    id: EMAIL_REPORT_GROUPS.SPAM_ASSASSIN,
    sidebarIcon: <SpamAssassinSidebarIcon />,
    resultsIcon: <SpamAssassinResultsIcon />,
    description: () => {
      if (!user) {
        return null;
      }

      return (
        <div className="group-results-header-description">
          <p>
            The SpamAssassin result is not included in the MX Score.
            Use the
            {' '}
            <Link
              href={routes.templateMXRiskAnalyze.path[0]}
              target="_blank"
              underline="hover"
              color="#17EDC3"
            >
              Template MX Risk
            </Link>
            {' '}
            tool for deep SpamAssassin analysis
          </p>
        </div>
      );
    },
  },
];

export const getGeneralState = (array) => array.reduce((acc, item) => {
  if (!acc) {
    return item.state;
  }

  if (acc.value === 'Fail') {
    return acc;
  }

  if (item.state.value === 'Fail') {
    if (item.tier < 3) {
      return item.state;
    }

    return {
      ...item.state,
      color: 'default',
      value: 'Info',
    };
  }

  return acc;
}, null);

const Results = () => {
  const params = useParams();
  const navigate = useNavigate();

  const serviceItemRefs = useRef({});

  const { data: currentUser, isLoading: isCurrentUserLoading } = userApi.useCurrentUser();

  const serviceGroupsData = getServiceGroupsData({ user: currentUser });

  const [activeItem, setActiveItem] = useState(serviceGroupsData[0].id);
  const [isShareReportModalVisible, setIsShareReportModalVisible] = useState(false);

  const [retryAttempt, setRetryAttempt] = useState(0);

  const [errorMessage, setErrorMessage] = useState({
    title: null,
    description: null,
    status: null,
  });
  const [isInvalid, setIsInvalid] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const { width } = useWindowDimensions();
  const mobileWidth = width <= 1250;

  const { data: menuItems, isLoading: isMenuItemsLoading } = mxrightApi.useMenuItemsList(params.email);
  const { data: meters, isLoading: isMetersLoading } = mxrightApi.useMetersList(menuItems && params.email);
  const { data: commonReportData, isLoading: isCommonDataLoading } = mxrightApi.useCommonData(menuItems && params.email);
  const { data: networkInfoData, isLoading: isNetworkInfoLoading } = mxrightApi.useNetworkInfo(menuItems && params.email);
  const { mutate: shareReport, isLoading: isShareReportLoading } = emailApi.useShareReport();
  const {
    data: items,
    isLoading: isReportItemsLoading,
    isError,
    refetch,
  } = mxrightApi.useResultsByGroup({
    groups: menuItems?.map((item) => item.id),
    email: params.email,
  }, {
    onError: (error) => {
      setErrorMessage({
        title: error.data['hydra:title'],
        description: error.data['hydra:description'],
        status: error.status,
      });

      if (error.status === 425) {
        const newRetryAttempt = retryAttempt + 1;

        if (newRetryAttempt <= MAX_RETRY_ATTEMPTS) {
          setRetryAttempt(newRetryAttempt);

          setTimeout(() => {
            refetch();
          }, 1.8 ** (newRetryAttempt) * 1000);
        }

        return;
      }

      if (error.status === 422 || error.status === 403) {
        setIsInvalid(true);

        return;
      }

      if (error.status === 410) {
        setIsExpired(true);
      }

      if (error.status === 417) {
        setRetryAttempt(MAX_RETRY_ATTEMPTS);
      }
    },
  });

  const isLoading = isCurrentUserLoading || isMenuItemsLoading || isMetersLoading
    || isCommonDataLoading || isNetworkInfoLoading || isReportItemsLoading;
  const isErrorState = retryAttempt === MAX_RETRY_ATTEMPTS || (isError && (isInvalid || isExpired));

  const sortedMenuItems = useMemo(() => menuItems?.sort((a, b) => {
    if (a.sortingOrder < b.sortingOrder) {
      return -1;
    }

    if (a.sortingOrder > b.sortingOrder) {
      return 1;
    }

    return 0;
  }), [menuItems]);

  const onSidebarItemClick = useCallback((serviceGroupName) => {
    setActiveItem(serviceGroupName);
  }, []);

  const onNavigateToMonitors = useCallback(() => {
    navigate(routes.dashboard.path);
  }, [navigate]);

  const onNavigateToEmailMonitors = useCallback(() => {
    navigate(routes.emailInfrastructure.path);
  }, [navigate]);

  const onShareIconClick = useCallback(() => {
    setIsShareReportModalVisible(true);
  }, []);

  const onShareSwitchChange = useCallback((isShare) => {
    shareReport({ email: commonReportData.toEmail, shared: isShare }, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['mxright.commonData', { email: commonReportData.toEmail }],
        });
      },
    });
  }, [shareReport, commonReportData?.toEmail]);

  const scrollToServiceItem = useCallback((record) => {
    const ref = serviceItemRefs.current[record.address];

    if (ref) {
      ref.openDescription();
      ref.scrollToItem();
    }
  }, []);

  const ipAndDomainRecords = useMemo(() => (networkInfoData
    ? [...networkInfoData.hostIpResult.ipRecords, ...networkInfoData.hostDomainResult.domainRecords]
    : []), [networkInfoData]);

  const ShareIcon = () => (
    <IconButton onClick={onShareIconClick}>
      <FontAwesomeIcon
        icon={faArrowUpFromSquare}
        fontSize={16}
        {...(commonReportData.shared ? {
          color: '#17EDC3',
        } : {
          fillOpacity: 0.56,
        })}
      />
    </IconButton>
  );

  return (
    <div className="results2-wrapper">
      {isShareReportModalVisible && (
      <ShareReportModal
        open={isShareReportModalVisible}
        onClose={() => setIsShareReportModalVisible(false)}
        linkToShare={window.location.href}
        toEmail={commonReportData.toEmail}
        isShareEnabled={commonReportData.shared}
        onSwitchChange={onShareSwitchChange}
        isSwitchDisabled={isShareReportLoading}
        isOwner={commonReportData.ownedByMe}
      />
      )}

      {!mobileWidth && (
      <div className={cn('sidebar', 'scroll', !currentUser && 'sidebar-no-user')}>
        <AuthLogoIcon className="auth-logo" onClick={onNavigateToMonitors} />

        <div className="line" />

        <div className="score-circle-wrapper">
          <ScoreCircle
            mxrScoreTotal={commonReportData ? 100 : undefined}
            mxrScoreTotalPass={commonReportData?.totalScore}
            ratingColor={commonReportData?.rating?.color}
            ratingLabel={commonReportData?.rating?.label}
            blured={isErrorState}
          />
        </div>

        {!isErrorState && (
        <>
          <div className={cn('email-for-container', !commonReportData?.fromEmail && 'email-for-container_empty')}>
            {commonReportData?.fromEmail && (
            <p>
              Test for
              {' '}
              {commonReportData.fromEmail}
            </p>
            )}

            {commonReportData?.ownedByMe && currentUser && <ReportHistorySelect />}
            {commonReportData && (!currentUser || !commonReportData.ownedByMe) && (
            <div className="result-at">
              <SmallClockIcon />

              <p>{new Date(commonReportData.resultAt).toLocaleString(navigator.language)}</p>
            </div>
            )}
          </div>

          {commonReportData && (
          <div className="share-wrapper">
            {(commonReportData.ownedByMe || commonReportData.shared) && (
              <ShareIcon />
            )}

            <IconButton onClick={() => { window.print(); }}>
              <DownloadIcon />
            </IconButton>
          </div>
          )}

          <div className="menu">
            {sortedMenuItems?.map((menuItem) => {
              const serviceGroup = serviceGroupsData.find(({ id }) => id === menuItem.id);

              return (
                <HashLink
                  to={`#${menuItem.id}`}
                  smooth
                  onClick={() => onSidebarItemClick(menuItem.id)}
                  key={menuItem.id}
                >
                  <div className={cn('sidebar-item', activeItem === menuItem.id && 'active-item')}>
                    <div>
                      {serviceGroup.sidebarIcon}
                      <p>{menuItem?.name}</p>
                    </div>

                    {!!menuItem.numFailed && (
                      <div className="error-counter">
                        <p>{menuItem.numFailed}</p>
                      </div>
                    )}
                  </div>
                </HashLink>
              );
            })}
          </div>
        </>
        )}
      </div>
      )}

      {!currentUser && !mobileWidth && (
      <CreateAccountHeader title="To monitor this email addresses and more, register for a Free Account" />
      )}

      <div className="results-content">
        {/* Print version starts */}
        <div className="print-header">
          <LogoLightIcon />
          <p>Email Deliverability Tools</p>
        </div>
        <div className="print-info">
          <p>MX Test for {commonReportData?.fromEmail}</p>
          <p>{moment(commonReportData?.resultAt).format('MM/DD/YYYY [at] h:mm:ss A')}</p>
        </div>
        {/* Print version ends */}

        {!!currentUser && !mobileWidth && (
        <header className="header">
          <div className="back-wrapper" onClick={onNavigateToEmailMonitors} aria-hidden="true">
            <ArrowLeftIcon />

            <p>Back to Monitors</p>
          </div>

          <div>
            <AuthMenu />
          </div>
        </header>
        )}

        {mobileWidth && (
        <div>
          {currentUser ? <Header mobile hideMenu /> : null}

          <div className="circle-content-wrapper">
            {!!currentUser && (
            <div className="back-wrapper" onClick={onNavigateToEmailMonitors} aria-hidden="true">
              <ArrowLeftIcon />

              <p>Back to Monitors</p>
            </div>
            )}

            <div className="score-circle-wrapper">
              <ScoreCircle
                mxrScoreTotal={commonReportData ? 100 : undefined}
                mxrScoreTotalPass={commonReportData?.totalScore}
                ratingColor={commonReportData?.rating?.color}
                ratingLabel={commonReportData?.rating?.label}
                blured={isErrorState}
              />
            </div>

            <div className="email-for-container">
              {commonReportData?.fromEmail && (
              <p>
                Test for
                {' '}
                {commonReportData.fromEmail}
              </p>
              )}

              {commonReportData?.ownedByMe && currentUser && (
              <ReportHistorySelect
                className="mobile-report-hisotry-select"
                initialCommonReportData={commonReportData}
              />
              )}
            </div>

            {commonReportData && (
            <div className="share-wrapper">
              {(commonReportData.ownedByMe || commonReportData.shared) && (
                <ShareIcon />
              )}
              <IconButton onClick={() => { window.print(); }}>
                <DownloadIcon />
              </IconButton>
              <IconButton onClick={refetch}>
                <RefreshIcon />
              </IconButton>
            </div>
            )}
          </div>
        </div>
        )}

        {!!meters?.length && !isErrorState && (
        <div className={cn('create-account-and-speedometers', !currentUser && 'create-account-and-speedometers-no-account')}>
          <div className={cn('speedometer-container', currentUser && 'speedometer-container-authorized')}>
            {meters.map((item) => (
              <Speedometer
                key={item.id}
                serviceGroup={item.name}
                passTestsCount={item.numPassed}
                totalTestsCount={item.numTotal}
                percent={item.percent}
              />
            ))}
          </div>
        </div>
        )}

        {!isInvalid && !isExpired && !items?.length
        && retryAttempt !== MAX_RETRY_ATTEMPTS && (
        <div className="loading-placeholder">
          <CupOfTeaIcon />

          <p>Waiting for your email</p>

          <p>
            If you have not sent the email to <span>{params.email}</span> yet, please do so now.
          </p>
        </div>
        )}

        {isError && (isInvalid || retryAttempt === MAX_RETRY_ATTEMPTS || isExpired) && (
        <div className="error-placeholder">
          <ErrorIcon />

          <p>{errorMessage.title}</p>

          <p>{errorMessage.description}</p>

          {errorMessage.status === 425 && (
          <Button variant="outlined" onClick={() => navigate(0)}>
            Refresh
          </Button>
          )}
        </div>
        )}

        {!isLoading && items?.length && (
        <div>
          {sortedMenuItems?.map((menuItem) => {
            const groupResults = serviceGroupsData.find(({ id }) => id === menuItem.id);

            const results = items.filter((item) => item.groupName === menuItem.id);

            const isSpam = menuItem.id === EMAIL_REPORT_GROUPS.SPAM;
            const spamBlocklistResults = isSpam ? results.filter((item) => item['@type'] === 'BlocklistResult') : [];

            return (
              <div
                className={cn('group-results-wrapper', 'pagebreak')}
                key={menuItem.id}
                id={menuItem.id}
              >
                <div className="group-results-header">
                  {groupResults.resultsIcon || <EmailResultsIcon />}

                  <div className="group-results-header-label">
                    <p>{menuItem.name}</p>
                    {groupResults.description && groupResults.description()}
                  </div>
                </div>

                {!!results?.length && (
                <div className="group-results-section-wrapper">
                  {!isSpam && results.map((item) => (
                    <GroupResultSection
                      key={`${menuItem.id}-${item.title}`}
                      groupResultsSection={item}
                      groupName={menuItem.id}
                    />
                  ))}

                  {isSpam && !!spamBlocklistResults.length && (
                  <BlacklistMatrix
                    scrollToServiceItem={scrollToServiceItem}
                    mobileWidth={mobileWidth}
                    items={spamBlocklistResults}
                    email={params.email}
                  />
                  )}

                  {isSpam && ipAndDomainRecords.length && (
                  <div className={cn('monitor-result-group-section', 'pagebreak')}>
                    <div className="group-section-title">
                      <div className="align-items-center">
                        <div className={cn('group-status-mark', {
                          default: true,
                        })}
                        >
                          <FontAwesomeIcon icon={faChartRadar} />
                        </div>

                        <div className="group-section-text">
                          <p>IP Blacklist & Reputation Status</p>
                        </div>
                      </div>
                    </div>

                    <div className="honeypot-results">
                      {ipAndDomainRecords.map((item) => (
                        <BlacklistServiceItem
                          key={item['@id']}
                          // eslint-disable-next-line no-return-assign
                          ref={(el) => (serviceItemRefs.current[item.title] = el)}
                          item={item}
                          mobileWidth={mobileWidth}
                          email={params.email}
                        />
                      ))}
                    </div>
                  </div>
                  )}
                </div>
                )}
              </div>
            );
          })}
        </div>
        )}

        {/* Print version starts */}
        <div className="print-view-report">
          <p>View the report:</p>
          <Link
            href={window.location.href}
            underline="hover"
            color="#009688"
          >
            {window.location.href}
          </Link>
        </div>
        <div className="print-footer">
          <LogoLightIcon />

          <p>© 2022 MX Right. All rights reserved.</p>

          <p>www.mxright.com</p>
        </div>
        {/* Print version ends */}
      </div>

      <footer>
        <div>
          <p className="rights">© {moment().year()} MX Right. All rights reserved.</p>
          {/* <a href="https://help.mxright.com" target="_blank" rel="noopener noreferrer"><p>Knowledge Base</p></a> */}
          <a href="https://api.mxright.com/v2/docs?ui=re_doc" target="_blank" rel="noopener noreferrer">
            <p>MX Right API</p>
          </a>
          <a href="https://mxright.com/privacy-policy" target="_blank" rel="noopener noreferrer"><p>Privacy Policy</p></a>
          <a href="https://mxright.com/terms" target="_blank" rel="noopener noreferrer"><p>Terms</p></a>
        </div>

        <div className="social-container">
          {/* <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><TwitterIcon /></IconButton>
          <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><LinkedinSmallIcon /></IconButton>
          <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><YoutubeIcon /></IconButton>
          <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><FacebookIcon /></IconButton> */}
        </div>
      </footer>
    </div>
  );
};

export default Results;
