import { useCallback, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import moment from 'moment';

import {
  AuthLogoIcon, ErrorIcon, TwitterIcon, SmallCheckIcon,
  LinkedinSmallIcon, YoutubeIcon, FacebookIcon, DownloadIcon,
  ArrowLeftIcon, RefreshIcon, LogoLightIcon, CrossIcon,
  EmailResultsIcon, CupOfTeaIcon, CheckIcon, MinusIcon,
} from 'assets/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartRadar } from '@fortawesome/sharp-light-svg-icons';

import { routes } from 'routes';

import { userApi } from 'resources/user';
import { mxrightApi } from 'resources/mxright';

import { Link, IconButton } from '@mui/material';

import ScoreCircle from 'components/ScoreCircle';
import Speedometer from 'components/Speedometer';
import AuthMenu from 'components/AuthMenu';
import Button from 'components/Button';
import CreateAccountHeader from 'components/CreateAccountHeader';

import Header from 'layouts/authorized/components/Header';

import useWindowDimensions from 'hooks/useWindowDimensions';

import GroupResultSection from 'pages/Results/components/GroupResultSection';
import BlacklistMatrix from 'pages/Results/components/BlacklistMatrix';
import BlacklistServiceItem from 'pages/Results/components/BlacklistServiceItem';

import { EMAIL_REPORT_GROUPS, getServiceGroupsData } from 'pages/Results';

import ReportHistorySelect from './components/ReportHistorySelect';

import './styles.scss';

const MAX_RETRY_ATTEMPTS = 5;

export const getChipValue = ({ state, mobileWidth, isClean }) => {
  const isSuccess = state?.value === 'Success';
  const isFail = state?.value === 'Fail';

  if (mobileWidth) {
    if (isSuccess) {
      return <SmallCheckIcon />;
    }

    return <CrossIcon width={18} height={18} style={{ marginTop: 3, marginLeft: -2 }} />;
  }

  if (isClean) {
    if (isSuccess) {
      return 'Clean';
    }

    if (isFail) {
      return 'Listed';
    }
  }

  return state?.value || '-';
};

const MonitorResults = () => {
  const navigate = useNavigate();
  const params = useParams();

  const serviceItemRefs = useRef({});

  const { data: currentUser } = userApi.useCurrentUser();

  const serviceGroupsData = getServiceGroupsData({ user: currentUser });

  const [activeItem, setActiveItem] = useState(serviceGroupsData[0].id);

  const [retryAttempt, setRetryAttempt] = useState(0);

  const [errorMessage, setErrorMessage] = useState({
    title: null,
    description: null,
    status: null,
  });
  const [isInvalid, setIsInvalid] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const { width } = useWindowDimensions();
  const mobileWidth = width <= 1250;

  const { data: menuItems } = mxrightApi.useMenuItemsList(params.email);
  const { data: meters } = mxrightApi.useMetersList(menuItems && params.email);
  const { data: commonReportData } = mxrightApi.useCommonData(menuItems && params.email);
  const { data: networkInfoData } = mxrightApi.useNetworkInfo(menuItems && params.email);
  const {
    data: items,
    isError,
    refetch,
  } = mxrightApi.useResultsByGroup({
    groups: menuItems?.map((item) => item.id),
    email: params.email,
  }, {
    onError: (error) => {
      setErrorMessage({
        title: error.data['hydra:title'],
        description: error.data['hydra:description'],
        status: error.status,
      });

      if (error.status === 425) {
        const newRetryAttempt = retryAttempt + 1;

        if (newRetryAttempt <= MAX_RETRY_ATTEMPTS) {
          setRetryAttempt(newRetryAttempt);

          setTimeout(() => {
            refetch();
          }, 1.8 ** (newRetryAttempt) * 1000);
        }

        return;
      }

      if (error.status === 422 || error.status === 403) {
        setIsInvalid(true);

        return;
      }

      if (error.status === 410) {
        setIsExpired(true);
      }

      if (error.status === 417) {
        setRetryAttempt(MAX_RETRY_ATTEMPTS);
      }
    },
  });

  const sortedMenuItems = useMemo(() => menuItems?.sort((a, b) => {
    if (a.sortingOrder < b.sortingOrder) {
      return -1;
    }

    if (a.sortingOrder > b.sortingOrder) {
      return 1;
    }

    return 0;
  }), [menuItems]);

  const onSidebarItemClick = useCallback((serviceGroupName) => {
    setActiveItem(serviceGroupName);
  }, []);

  const onNavigateToMonitors = useCallback(() => {
    navigate(routes.dashboard.path);
  }, [navigate]);

  const onNavigateToEmailMonitors = useCallback(() => {
    navigate(routes.emailInfrastructure.path);
  }, [navigate]);

  const onRefresh = useCallback(() => {
    refetch();
  }, [refetch]);

  const scrollToServiceItem = useCallback((record) => {
    const ref = serviceItemRefs.current[record.address];

    if (ref) {
      ref.openDescription();
      ref.scrollToItem();
    }
  }, []);

  const ipAndDomainRecords = useMemo(() => (networkInfoData
    ? [...networkInfoData.hostIpResult.ipRecords, ...networkInfoData.hostDomainResult.domainRecords]
    : []), [networkInfoData]);

  return (
    <div className="monitor-results-wrapper">
      {!mobileWidth && (
      <div className={cn('sidebar', 'scroll', !currentUser && 'sidebar-no-user')}>
        <AuthLogoIcon className="auth-logo" onClick={onNavigateToMonitors} />

        <div className="line" />

        <div className="score-circle-wrapper">
          <ScoreCircle
            mxrScoreTotal={commonReportData ? 100 : undefined}
            mxrScoreTotalPass={commonReportData?.totalScore}
            ratingColor={commonReportData?.rating?.color}
            ratingLabel={commonReportData?.rating?.label}
            blured={isError}
          />
        </div>

        {!isError && (
        <>
          <div className="email-for-container">
            {commonReportData?.fromEmail && (
            <p>
              Test for
              {' '}
              {commonReportData.fromEmail}
            </p>
            )}

            {commonReportData && (
            <ReportHistorySelect
              show={!!commonReportData?.ownedByMe}
              resultAt={commonReportData.resultAt}
            />
            )}
          </div>

          {commonReportData && (
          <div className="share-wrapper">
            <IconButton onClick={() => { window.print(); }}>
              <DownloadIcon />
            </IconButton>
          </div>
          )}

          <div className="menu">
            {sortedMenuItems?.map((menuItem) => {
              const serviceGroup = serviceGroupsData.find(({ id }) => id === menuItem.id);

              return (
                <HashLink
                  to={`#${menuItem.id}`}
                  smooth
                  onClick={() => onSidebarItemClick(menuItem.id)}
                  key={menuItem.id}
                >
                  <div className={cn('sidebar-item', activeItem === menuItem.id && 'active-item')}>
                    <div>
                      {serviceGroup.sidebarIcon}
                      <p>{menuItem?.name}</p>
                    </div>

                    {!!menuItem.numFailed && (
                      <div className="error-counter">
                        <p>{menuItem.numFailed}</p>
                      </div>
                    )}
                  </div>
                </HashLink>
              );
            })}
          </div>
        </>
        )}
      </div>
      )}

      {!currentUser && !mobileWidth && (
      <CreateAccountHeader title="To monitor this email addresses and more, register for a Free Account" />
      )}

      <div className="results-content">
        {/* Print version starts */}
        <div className="print-header">
          <LogoLightIcon />
          <p>Email Deliverability Tools</p>
        </div>
        <div className="print-info">
          <p>MX Test for {commonReportData?.fromEmail}</p>
          <p>{moment(commonReportData?.resultAt).format('MM/DD/YYYY [at] h:mm:ss A')}</p>
        </div>
        {/* Print version ends */}

        {!!currentUser && !mobileWidth && (
        <header className="header">
          <div className="back-wrapper" onClick={onNavigateToEmailMonitors} aria-hidden="true">
            <ArrowLeftIcon />

            <p>Back to Monitors</p>
          </div>

          <div>
            <AuthMenu />
          </div>
        </header>
        )}

        {mobileWidth && (
        <div>
          <Header mobile hideMenu />

          <div className="circle-content-wrapper">
            <div className="back-wrapper" onClick={onNavigateToEmailMonitors} aria-hidden="true">
              <ArrowLeftIcon />

              <p>Back to Monitors</p>
            </div>

            <div className="score-circle-wrapper">
              <ScoreCircle
                mxrScoreTotal={commonReportData ? 100 : undefined}
                mxrScoreTotalPass={commonReportData?.totalScore}
                ratingColor={commonReportData?.rating?.color}
                ratingLabel={commonReportData?.rating?.label}
                blured={isError}
              />
            </div>

            <div className="email-for-container">
              {commonReportData?.fromEmail && (
              <p>
                Test for
                {' '}
                {commonReportData.fromEmail}
              </p>
              )}

              {commonReportData?.ownedByMe && (
              <ReportHistorySelect
                className="mobile-report-hisotry-select"
                show={!!commonReportData?.ownedByMe}
                resultAt={commonReportData.resultAt}
              />
              )}
            </div>

            {commonReportData && (
            <div className="share-wrapper">
              <IconButton onClick={() => { window.print(); }}>
                <DownloadIcon />
              </IconButton>
              <IconButton onClick={onRefresh}>
                <RefreshIcon />
              </IconButton>
            </div>
            )}
          </div>
        </div>
        )}

        {!!meters?.length && !isError && (
        <div className={cn('create-account-and-speedometers', !currentUser && 'create-account-and-speedometers-no-account')}>
          <div className={cn('speedometer-container', currentUser && 'speedometer-container-authorized')}>
            {meters.map((item) => (
              <Speedometer
                key={item.id}
                serviceGroup={item.name}
                passTestsCount={item.numPassed}
                totalTestsCount={item.numTotal}
                percent={item.percent}
              />
            ))}
          </div>
        </div>
        )}

        {!isInvalid && !isExpired && !items?.length
        && retryAttempt !== MAX_RETRY_ATTEMPTS && (
        <div className="loading-placeholder">
          <CupOfTeaIcon />

          <p>Waiting for your email</p>

          <p>
            If you have not sent the email to <span>{params.email}</span> yet, please do so now.
          </p>
        </div>
        )}

        {isError && (isInvalid || retryAttempt === MAX_RETRY_ATTEMPTS || isExpired) && (
        <div className="error-placeholder">
          <ErrorIcon />

          <p>{errorMessage.title}</p>

          <p>{errorMessage.description}</p>

          {errorMessage.status === 425 && (
          <Button variant="outlined" onClick={() => navigate(0)}>
            Refresh
          </Button>
          )}
        </div>
        )}

        {items?.length && (
        <div>
          {sortedMenuItems?.map((menuItem) => {
            const groupResults = serviceGroupsData.find(({ id }) => id === menuItem.id);

            const results = items.filter((item) => item.groupName === menuItem.id);

            const isSpam = menuItem.id === EMAIL_REPORT_GROUPS.SPAM;
            const spamBlocklistResults = isSpam ? results.filter((item) => item['@type'] === 'BlocklistResult') : [];

            return (
              <div
                className={cn('group-results-wrapper', 'pagebreak')}
                key={menuItem.id}
                id={menuItem.id}
              >
                <div className="group-results-header">
                  {groupResults.resultsIcon || <EmailResultsIcon />}

                  <div className="group-results-header-label">
                    <p>{menuItem.name}</p>
                    {groupResults.description && groupResults.description()}
                  </div>
                </div>

                {!!results?.length && (
                <div className="group-results-section-wrapper">
                  {!isSpam && results.map((item) => (
                    <GroupResultSection
                      key={`${menuItem.id}-${item.title}`}
                      groupResultsSection={item}
                      groupName={menuItem.id}
                    />
                  ))}

                  {isSpam && !!spamBlocklistResults.length && (
                  <BlacklistMatrix
                    scrollToServiceItem={scrollToServiceItem}
                    mobileWidth={mobileWidth}
                    items={spamBlocklistResults}
                    email={params.email}
                  />
                  )}

                  {isSpam && ipAndDomainRecords.length && (
                  <div className={cn('monitor-result-group-section', 'pagebreak')}>
                    <div className="group-section-title">
                      <div className="align-items-center">
                        <div className={cn('group-status-mark', {
                          default: true,
                        })}
                        >
                          <FontAwesomeIcon icon={faChartRadar} />
                        </div>

                        <div className="group-section-text">
                          <p>IP Blacklist & Reputation Status</p>
                        </div>
                      </div>
                    </div>

                    <div className="honeypot-results">
                      {ipAndDomainRecords.map((item) => (
                        <BlacklistServiceItem
                          key={item['@id']}
                          // eslint-disable-next-line no-return-assign
                          ref={(el) => (serviceItemRefs.current[item.title] = el)}
                          item={item}
                          mobileWidth={mobileWidth}
                          email={params.email}
                        />
                      ))}
                    </div>
                  </div>
                  )}
                </div>
                )}
              </div>
            );
          })}
        </div>
        )}

        {/* Print version starts */}
        <div className="print-view-report">
          <p>View the report:</p>
          <Link
            href={window.location.href}
            underline="hover"
            color="#009688"
          >
            {window.location.href}
          </Link>
        </div>
        <div className="print-footer">
          <LogoLightIcon />

          <p>© 2022 MX Right. All rights reserved.</p>

          <p>www.mxright.com</p>
        </div>
        {/* Print version ends */}
      </div>

      <footer>
        <div>
          <p className="rights">© {moment().year()} MX Right. All rights reserved.</p>
          {/* <a href="https://help.mxright.com" target="_blank" rel="noopener noreferrer"><p>Knowledge Base</p></a> */}
          <a href="https://api.mxright.com/v2/docs?ui=re_doc" target="_blank" rel="noopener noreferrer">
            <p>MX Right API</p>
          </a>
          <a href="https://mxright.com/privacy-policy" target="_blank" rel="noopener noreferrer"><p>Privacy Policy</p></a>
          <a href="https://mxright.com/terms" target="_blank" rel="noopener noreferrer"><p>Terms</p></a>
        </div>

        <div className="social-container">
          {/* <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><TwitterIcon /></IconButton>
          <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><LinkedinSmallIcon /></IconButton>
          <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><YoutubeIcon /></IconButton>
          <IconButton onClick={() => { window.location.href = 'https://mxright.com'; }}><FacebookIcon /></IconButton> */}
        </div>
      </footer>
    </div>
  );
};

export default MonitorResults;
